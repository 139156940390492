import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmService} from "../../../services/confirm.service";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnDestroy{

  loading = true

  constructor(
    private confirmService: ConfirmService,
    private _ar: ActivatedRoute,
    private router: Router
  ) {
    _ar.queryParamMap.subscribe(params => {
      console.log(params);
      let resetPassword = params.get('pwd');
      confirmService.userId = params.get('u');
      confirmService.code = params.get('code');
      confirmService.email = params.get('em');
      // if (resetPassword) {
      //   confirmService.action = 'reset-password';
      //   router.navigateByUrl('reset-password');
      // } else {
        confirmService.action = 'confirm-email';
        router.navigateByUrl('email-confirmation');
      // }
    });
  }

  ngOnDestroy() {
    this.loading = false;
  }

}
