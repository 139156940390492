/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoginVM } from './login-vm';


export interface LoginVMResponseVM { 
    message?: string | null;
    status?: string | null;
    data?: LoginVM;
}

