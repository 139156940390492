/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterVM } from './filter-vm';
import { PaginationVM } from './pagination-vm';
import { CompanyVM } from './company-vm';


export interface CompanyVMListPaginationVMFilterResponse2VM { 
    message?: string | null;
    status?: string | null;
    pagination?: PaginationVM;
    data?: Array<CompanyVM> | null;
    filter?: FilterVM;
}

