import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TruncatePipe} from "./pipes/truncate.pipe";
import {TicketLifecycleDirective} from "./directives/ticket-lifecycle.directive";
import {ButtonWithLoaderComponent} from "./components/button-with-loader/button-with-loader.component";
import {AlertMessageComponent} from "./components/alert-message/alert-message.component";
import {DateOnlyPipe} from './pipes/dateonly.pipe';
import {TimeOnlyPipe} from "./pipes/timeonly";
import {DatetimePipe} from "./pipes/datetime.pipe";
import {MaskPipe} from "./pipes/mask.pipe";
import {NairaSignDirective} from "./directives/naira-sign/naira-sign.directive";
import {AmountPipe} from "./pipes/amount.pipe";
import {MinTwoDigitsPipePipe} from "./pipes/min-two-digits-pipe.pipe";
import {FileUrlPipe} from "./pipes/file-url.pipe";
import {CamelCaseToSpacePipe} from "./pipes/camel-case-to-space.pipe";
import {EnumToArrayPipe} from "./pipes/enum-to-array.pipe";
import {UnderscoreToSpacePipe} from "./pipes/underscore-to-space.pipe";
import {RouterModule} from "@angular/router";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {DirectivesModule} from "./directives/directives.module";
import {EmComponent} from "./components/em/em.component";
import {FieldErrorComponent} from "./components/field-error/field-error.component";
import {SignUpSuccessModalComponent} from './components/sign-up-success-modal/sign-up-success-modal.component';
import {LayoutComponent} from "./components/layout/layout.component";


@NgModule({
  declarations: [
    UnderscoreToSpacePipe,
    EnumToArrayPipe,
    CamelCaseToSpacePipe,
    FileUrlPipe,
    MinTwoDigitsPipePipe,
    AmountPipe,
    DatetimePipe,
    TimeOnlyPipe,
    DateOnlyPipe,
    MaskPipe,
    AlertMessageComponent,
    ButtonWithLoaderComponent,
    TruncatePipe,
    EmComponent,
    FieldErrorComponent,
    LayoutComponent,
    SignUpSuccessModalComponent
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    DirectivesModule,
    NgOptimizedImage
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    DirectivesModule,
    UnderscoreToSpacePipe,
    EnumToArrayPipe,
    FileUrlPipe,
    MinTwoDigitsPipePipe,
    AmountPipe,
    NairaSignDirective,
    NairaSignDirective,
    CamelCaseToSpacePipe,
    DatetimePipe,
    TimeOnlyPipe,
    DateOnlyPipe,
    AlertMessageComponent,
    ButtonWithLoaderComponent,
    TruncatePipe,
    EmComponent,
    FieldErrorComponent,
    SignUpSuccessModalComponent,
    LayoutComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
}
