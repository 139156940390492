import {Component, OnInit} from '@angular/core';
import {XAlertMessage} from "../../../shared/components/alert-message/alert-message.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../../../../../sdk/figoseal-api-sdk";
import {HelperService} from "../../../services/helper.service";
import {ConfirmService} from "../../../services/confirm.service";
import {Router} from "@angular/router";
import {Utils} from "../../../shared/utils/utils";

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css']
})
export class EmailConfirmationComponent implements OnInit {

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };
  action: any;

  form: FormGroup;
  passwordFieldType: string = 'password';
  isAuthenticating: boolean;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private helperService: HelperService,
    private confirmService: ConfirmService,
    private router: Router,
  ) {
    this.action = confirmService.action;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [this.action ? this.confirmService.email : '', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]],
      password: ['', [Validators.required]]
    });
  }

  confirmEmail() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isAuthenticating = true;

    const confirmEmailDTO = {
      ...this.form.getRawValue(),
      userId: this.confirmService.userId,
      code: this.confirmService.code
    }
    this.accountService.accountConfirmEmailPost({confirmEmailDTO})
      .subscribe({
        next: async (value) => {
          console.log(value);
          this.router.navigateByUrl("login");
          this.isAuthenticating = false
        },
        error: (error: unknown) => {
          this.isAuthenticating = false;
          this.showMessage(this.helperService.getError(error), 'danger');
        }
      })
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }


}
