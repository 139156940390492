import {Component, OnInit} from '@angular/core';
import {
  AdminService, AdminUserDTO,
  AdminUsersPostRequestParams, AdminUserSummaryDTO, FilterVM,
} from "../../../../../../sdk/figoseal-api-sdk";
import {UserInfo} from "../../../../services/user";
import {BsModalService} from "ngx-bootstrap/modal";
import {EditPermissionComponent} from "./edit-permission/edit-permission.component";
import {XAlertMessage} from "../../../../shared/components/alert-message/alert-message.component";
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  query = new FormControl("")
  filterForm: FormGroup;
  rolesOption = ['Head Admin', 'Financial Officer', 'Compliance Officer'] 

  users: Array<AdminUserDTO>;
  filter: FilterVM;

  usersSummary: Array<AdminUserSummaryDTO>;
  totalAdmin: number;

  filterEmpty = true;

  loading = false;

  constructor(
    public userInfo: UserInfo,
    private adminService: AdminService,
    private modalService: BsModalService,
    private _fb: FormBuilder
  ) { 
    this.filterForm = _fb.group({
      role: [],
      status: []
    })
  }

  get roles() {
    return this.filterForm.controls['roles'] as FormArray;
  }

  ngOnInit(): void {
    this.loading = true
    this.getUsers();
    this.getUsersSummary();
  }

  getUsersSummary() {
    this.adminService.adminUsersSummaryPost()
      .subscribe(value => {
        console.log(value)
        const summary = value.data;
        this.usersSummary = summary;
        this.totalAdmin = summary.map(s=> s.count).reduce((a, b) => a + b);
      })
  }

  filterMembers() {
    const {role, status} = this.filterForm.getRawValue();
    const query = this.query.value;
    this.getUsers(query, status, role ? role.toLocaleLowerCase() : '');
  }

  resetFilter() {
    this.filterForm.reset()
    this.getUsers()
  }

  getUsers(query?: string, status?: string, role?: string, page?: number) {
    const queryParams: AdminUsersPostRequestParams = {
      status: status,
      role: role,
      query: query,
      page: page ? page : 1,
      pageLimit: 20,
    }
    this.adminService.adminUsersPost(queryParams)
      .subscribe({
        next: value => {
          console.log(value)
          this.users = value.data;
          this.filter = value.filter;
          this.loading = false
        },
        error: err => {
          this.loading = false;
        }
      })
  }

  updateUserPermission(updatePermissionDTO: {role: string, userId: string}) {
    this.adminService.adminUserUpdatePermisionPost({updatePermissionDTO})
      .subscribe({
        next: value => {
          this.getUsersSummary();
          this.getUsers(this.filter.query, null, null, this.filter.page);
          this.showMessage('user has been updated successfully', 'success');
        },
        error: err => {
          this.showMessage(err.error.message, 'danger');
        }
      })
  }

  showModal(user: AdminUserDTO) {
    const bsModal = this.modalService.show(EditPermissionComponent, {
      initialState: {
        user
      },
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered',
      keyboard: false
    });
    bsModal.content.update.subscribe((role: string) => {
      this.updateUserPermission({userId: user.id, role});
      bsModal.hide();
    });
    bsModal.content.cancel.subscribe(() => {
      bsModal.hide();
    });
  }

  showMessage(message: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = message;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

}
