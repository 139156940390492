import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Utils} from "../../../../../shared/utils/utils";
import {AdminService, BusinessService} from "../../../../../../../sdk/figoseal-api-sdk";
import {XAlertMessage} from "../../../../../shared/components/alert-message/alert-message.component";
import {HelperService} from "../../../../../services/helper.service";
import {BusinessInfo} from "../../../../../services/business";

@Component({
  selector: 'app-invite-team-member',
  templateUrl: './invite-team-member.component.html',
  styleUrls: ['./invite-team-member.component.css']
})
export class InviteTeamMemberComponent implements OnInit {
  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  businessId: string;
  parentForm: FormGroup;

  loading = false;

  constructor(
    private businessInfo: BusinessInfo,
    private _fb: FormBuilder,
    private businessService: BusinessService,
    private helperService: HelperService,
  ) {
    this.parentForm = _fb.group({
      forms: _fb.array([
        this.buildForm(),
      ], this.uniqueEmailValidator())
    })
  }

  ngOnInit() {
    this.businessInfo.business.subscribe(value => {
      this.businessId = value?.id;
    })
  }

  get forms() {
    return this.parentForm.controls['forms'] as FormArray;
  }

  buildForm(): FormGroup {
    return this._fb.group({
      email: ['', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]],
      fullName: ['', [Validators.required, Validators.maxLength(Utils.NAME_FIELD_MAX_LENGTH), Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH)]],
      productIds: [[]],
      grantAdminAccess: [false]
    })
  }

  uniqueEmailValidator() {
    return (control: AbstractControl) => {
      const value: Array<any> = control.value
        .map(i => i.email)
        .filter(i => i);
      if (value.length <= 1) return null;
      const sample = value
        .filter((u, index) => u && value.indexOf(u) === index)
      return value.length != sample.length ? {uniqueEmailErr: 'Email should be unique for all users'} : null;
    }
  }

  addInviteForm() {
    this.forms.push(this.buildForm());
  }

  removeInviteForm(index: number) {
    this.forms.removeAt(index);
  }

  submitInviteForm() {
    if (this.forms.invalid) {
      this.forms.markAllAsTouched();
      return;
    }
    this.loading = true;
    const rawValue = this.forms.getRawValue()
    console.log(rawValue)

    const users = [...rawValue]
    this.businessService.businessUserInvitePost({inviteUsersDTO: {businessId: this.businessId, users}})
      .subscribe({
        next: value => {
          this.loading = false;
          this.showMessage('user have been invited', 'success');
        },
        error: err => {
          this.showMessage(this.helperService.getError(err), 'danger');
          this.loading = false
        }
      });
  }

  showMessage(message: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = message;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

}
