/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentStatus } from './document-status';


export interface Document { 
    id?: string;
    fullUrl: string;
    localUrl: string;
    name: string;
    ownerId: string;
    dateAdded: string;
    status: DocumentStatus;
    lastModified?: string;
    modifiedBy?: string | null;
    documentType?: string | null;
    readonly documentStatus?: string | null;
}

