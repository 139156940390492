import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagementComponent } from './management/management.component';
import { InviteUserComponent } from './management/invite-user/invite-user.component';
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../../shared/shared.module";
import { EditPermissionComponent } from './management/edit-permission/edit-permission.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent,
    outlet: 'admin'
  },
  {
    path: 'management',
    component: ManagementComponent,
    outlet: 'admin',
  },
  {
    path: 'management/invite-user',
    component: InviteUserComponent,
    outlet: 'admin',
  }
]

@NgModule({
  declarations: [
    DashboardComponent,
    ManagementComponent,
    InviteUserComponent,
    EditPermissionComponent,
  ],
  imports: [CommonModule, RouterModule.forChild(routes), ReactiveFormsModule, SharedModule, NgOptimizedImage],
})
export class AdminModule { }
