import {BehaviorSubject} from "rxjs";
import {VAspNetUser} from "../../../sdk/figoseal-api-sdk";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UserInfo {

  private _user: BehaviorSubject<VAspNetUser> = new BehaviorSubject<VAspNetUser>(null);


  get user(): BehaviorSubject<VAspNetUser> {
    return this._user;
  }

  set user(user: VAspNetUser) {
    this._user.next(user);
  }
}
