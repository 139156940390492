import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserInfo} from "../../../services/user";
import {UserService, VAspNetUser} from "../../../../../sdk/figoseal-api-sdk";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  @Input() access: any;

  title: string;

  constructor(
    public userInfo: UserInfo,
    private userService: UserService,
  ) {
    this.getUserInfo();
  }

  buildLink(uri: string[]) {
    return {
      [this.access]: uri
    }
  }

  activeLinkChange(event: any, titleChange: string) {
    if (event) this.title = titleChange;
  }

  getUserInfo(): void {
    this.userService.userMeGet()
      .subscribe({
        next: value => {
          this.userInfo.user = value.data;
        }
      })
  }



}
