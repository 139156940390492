/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDetailProduct } from './user-detail-product';


export interface CompanyUserDetailDTO { 
    id?: string | null;
    fullName?: string | null;
    email?: string | null;
    status?: string | null;
    avatarUrl?: string | null;
    hasAdminAccess?: boolean;
    products?: Array<UserDetailProduct> | null;
}

