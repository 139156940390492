/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VAspNetUser { 
    id?: string | null;
    fullName?: string | null;
    dateRegistered?: string | null;
    lastLogin?: string | null;
    forcePasswordChange?: boolean;
    avatarUrl?: string | null;
    country?: string | null;
    deleted?: boolean;
    dateDeleted?: string | null;
    address?: string | null;
    state?: string | null;
    city?: string | null;
    userName?: string | null;
    email?: string | null;
    normalizedEmail?: string | null;
    phoneNumber?: string | null;
    roles?: string | null;
    companyId?: string | null;
    companyName?: string | null;
    status?: string | null;
    readonly firstName?: string | null;
    readonly lastName?: string | null;
}

