import {Component, OnInit} from '@angular/core';
import {
  BusinessService,
  CompanyUserDetailDTO,
  Product, ProductService,
} from "../../../../../../../sdk/figoseal-api-sdk";
import {ActivatedRoute} from "@angular/router";
import {HelperService} from "../../../../../services/helper.service";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {BusinessInfo} from "../../../../../services/business";
import {XAlertMessage} from "../../../../../shared/components/alert-message/alert-message.component";

@Component({
  selector: 'app-edit-permission',
  templateUrl: './edit-permission.component.html',
  styleUrls: ['./edit-permission.component.css']
})
export class EditPermissionComponent implements OnInit {

  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  memberId: string;
  businessId: string;

  member: CompanyUserDetailDTO;
  businessProducts: Array<Product>;

  loading = false;

  form: FormGroup;
  updating = false;

  constructor(
    private businessInfo: BusinessInfo,
    private businessService: BusinessService,
    private productService: ProductService,
    private helperService: HelperService,
    private fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.loading = true;
    _activatedRoute.params.subscribe(params => {
      this.memberId = params['memberId'];
    })
  }

  ngOnInit() {
    this.form = this.fb.group({
      hasAdminAccess: [false],
      products: this.fb.array([])
    })
    this.businessInfo.business.subscribe(value => {
      this.businessId = value?.id;
      this.getTeamMemberInfo();
      this.getBusinessProducts(value?.id);
    })
  }

  get products() {
    return this.form.controls['products'] as FormArray;
  }

  getTeamMemberInfo() {
    this.businessService.businessUserIdGet({id: this.memberId})
      .subscribe({
        next: result => {
          this.member = result.data;
          this.form.controls['hasAdminAccess'].setValue(this.member.hasAdminAccess);
          this.loading = false
        },
        error: err => {
          this.loading = false;
          console.log(this.helperService.getError(err));
        }
      })
  }

  getBusinessProducts(businessId: string) {
    return this.productService.productAllBusinessIdPost({businessId})
      .subscribe(value => {
        this.businessProducts = value.data;
        const assignedProducts = this.member ? this.member.products.map(p => p.id) : [];
        return this.businessProducts
          .map(p => {
            if (assignedProducts.includes(p.id)) return this.products.push(this.fb.group({product: [p.id]}));
            return this.products.push(this.fb.group({product: ['']}));
          });
      })
  }

  updateMemberPermission(){
    this.updating = true;
    const formRaw = this.form.getRawValue();
    if (!this.validateChange(formRaw.products) && !this.checkAccessChange(formRaw.hasAdminAccess)) {
      this.showMessage('perform a change to proceed', "warning");
      this.updating = false;
      return;
    }
    this.businessService.businessUserUpdatePut({updateCompanyUserDetail: {...formRaw, id: this.memberId}})
      .subscribe({
        next: result => {
          this.showMessage("user permission update", "success");
          this.updating = false;
        },
        error: err => {
          this.showMessage(this.helperService.getError(err), 'danger');
          this.updating = false;
        }
      })
  }

  validateChange(products: Array<any>) {
    const memberProducts = this.member.products;
    if (products.length === 0 && memberProducts.length == 0) return false;
    if (products.length === 0 || memberProducts.length == 0) return true;
    return new Set(products.map(p => p.id)) != new Set(memberProducts.map(p => p.id));
  }

  checkAccessChange(hasAdminAccess: boolean) {
    return this.member.hasAdminAccess != hasAdminAccess;
  }

  showMessage(message: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = message;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }
}
