import { Component } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Utils} from "../../../../../shared/utils/utils";
import {AdminService} from "../../../../../../../sdk/figoseal-api-sdk";
import {XAlertMessage} from "../../../../../shared/components/alert-message/alert-message.component";
import {HelperService} from "../../../../../services/helper.service";

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent {
  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };

  parentForm: FormGroup;
  roleOptions = ['Head Admin', 'Compliance Officer', 'Financial Officer'];

  loading = false;

  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private helperService: HelperService,
  ) {
    this.parentForm = _fb.group({
      forms: _fb.array([
        this.buildForm(),
      ], this.uniqueEmailValidator())
    })
  }

  get forms() {
    return this.parentForm.controls['forms'] as FormArray;
  }

  buildForm(): FormGroup {
    return this._fb.group({
      email: ['', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]],
      fullName: ['', [Validators.required, Validators.maxLength(Utils.NAME_FIELD_MAX_LENGTH), Validators.minLength(Utils.NAME_FIELD_MIN_LENGTH)]],
      role: ['Select Admin Role', [Validators.required, this.roleValidator()]],
    })
  }

  roleValidator() {
    return (control: AbstractControl) => {
      const value = control.value;
      return !this.roleOptions.includes(value) ? {message: 'Invalid Role selected'} : null;
    }
  }

  uniqueEmailValidator() {
    return (control: AbstractControl) => {
      const value: Array<any> = control.value
        .map(i => i.email)
        .filter(i => i);
      if (value.length <= 1) return null;
      const sample = value
        .filter((u, index) => u && value.indexOf(u) === index)
      return value.length != sample.length ? {uniqueEmailErr: 'Email should be unique for all users'} : null;
    }
  }

  addInviteForm() {
    this.forms.push(this.buildForm());
  }

  removeInviteForm(index: number) {
    this.forms.removeAt(index);
  }

  submitInviteForm() {
    if (this.forms.invalid) {
      this.forms.markAllAsTouched();
      return;
    }
    this.loading = true;
    const rawValue = this.forms.getRawValue()

    const users = [...rawValue]
    this.adminService.adminUserCreatePost({createAdminUser: {users}})
      .subscribe({
        next: value => {
          this.loading = false;
          this.showMessage('user have been invited', 'success');
        },
        error: err => {
          this.showMessage(this.helperService.getError(err), 'danger');
          this.loading = false
        }
      });
  }

  showMessage(message: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = message;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

}
