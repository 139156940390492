import {Component} from '@angular/core';
import {UserService} from "../../../../../../sdk/figoseal-api-sdk";
import {UserInfo} from "../../../../services/user";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

}
