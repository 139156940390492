/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Company } from './company';


export interface LoginVM { 
    token?: string | null;
    expDate?: string;
    fullName?: string | null;
    email?: string | null;
    phone?: string | null;
    roles?: Array<string> | null;
    avatar?: string | null;
    isActive?: boolean;
    company?: Company;
    userId?: string;
    forcePasswordChange?: boolean;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    status?: string | null;
}

