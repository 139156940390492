import { Component } from '@angular/core';
import {BusinessService} from "../../../../../../sdk/figoseal-api-sdk";
import {BusinessInfo} from "../../../../services/business";
import {HelperService} from "../../../../services/helper.service";

@Component({
  selector: 'app-business-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {

  constructor(
    public businessInfo: BusinessInfo,
    private businessService: BusinessService,
  ) {

    this.businessService.businessMyBusinessGet()
      .subscribe({
        next: (value) => {
          this.businessInfo.business = value.data
        },
      })
  }

}
