import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {AdminUserDTO} from "../../../../../../../sdk/figoseal-api-sdk";
import _default from "chart.js/dist/core/core.layouts";
import update = _default.update;

@Component({
  selector: 'app-edit-permission',
  templateUrl: './edit-permission.component.html',
  styleUrls: ['./edit-permission.component.css']
})
export class EditPermissionComponent implements OnInit{

  @Input('user') user: AdminUserDTO;

  @Output()
  update: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  cancel = new EventEmitter();

  roleOptions = ['Head Admin', 'Compliance Officer', 'Financial Officer'];
  role: FormControl;

  ngOnInit(): void {
    this.role = new FormControl(this.user.roles, Validators.required);
  }

  saveChanges() {
    if (!this.role.valid) {
      this.role.markAsTouched();
      return;
    }
    this.update.emit(this.role.value);
  }

  dismissClick() {
    this.cancel.emit()
  }
}
