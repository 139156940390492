import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ForbiddenComponent} from "./pages/extranet/forbidden/forbidden.component";
import {ForgotPasswordComponent} from "./pages/extranet/forgot-password/forgot-password.component";
import {LoginComponent} from "./pages/extranet/login/login.component";
import {SignUpComponent} from "./pages/extranet/sign-up/sign-up.component";
import {ResetPasswordComponent} from "./pages/extranet/reset-password/reset-password.component";
import {LayoutComponent as AdminLayoutComponent} from "./pages/intranet/admin/layout/layout.component";
import {LayoutComponent as BusinessLayoutComponent} from "./pages/intranet/business/layout/layout.component";
import {ConfirmComponent} from "./pages/extranet/confirm/confirm.component";
import {EmailConfirmationComponent} from "./pages/extranet/email-confirmation/email-confirmation.component";

const routes: Routes = [
  {
    path: '',
    component: SignUpComponent,
    children: [],
    data: {
      showPageHeader: true,
      showPageFooter: false,
      isExtranet: true
    },
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },
  {
    path: 'ConfirmationCode',
    component: ConfirmComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true,
    }
  },
  {
    path: 'email-confirmation',
    component: EmailConfirmationComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true,
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    }
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    data: {
      isExtranet: false
    }
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('./pages/intranet/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'business',
    component: BusinessLayoutComponent,
    loadChildren: () =>
      import('./pages/intranet/business/business.module').then((m) => m.BusinessModule),
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

const routerOptions: ExtraOptions = {
  // enableTracing: true, // Enable tracing
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
