import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {SharedModule} from "../../../shared/shared.module";
import { ManagementComponent } from './management/management.component';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {InviteTeamMemberComponent} from "./management/invite-team-member/invite-team-member.component";
import { EditPermissionComponent } from './management/edit-permission/edit-permission.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent,
    outlet: 'business'
  },
  {
    path: 'management',
    component: ManagementComponent,
    outlet: 'business',
  },
  {
    path: 'management/invite-user',
    component: InviteTeamMemberComponent,
    outlet: 'business',
  },
  {
    path: 'management/edit-member-permissions/:memberId',
    component: EditPermissionComponent,
    outlet: 'business',
  }
]


@NgModule({
  declarations: [
    ManagementComponent,
    InviteTeamMemberComponent,
    LayoutComponent,
    DashboardComponent,
    EditPermissionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ]
})
export class BusinessModule { }
