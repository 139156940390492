import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LandingPageComponent} from './pages/extranet/landing-page/landing-page.component';
import {ForbiddenComponent} from './pages/extranet/forbidden/forbidden.component';
import {SignUpComponent} from './pages/extranet/sign-up/sign-up.component';
import {LoginComponent} from './pages/extranet/login/login.component';
import {ForgotPasswordComponent} from './pages/extranet/forgot-password/forgot-password.component';
import {CommonModule, CurrencyPipe, NgOptimizedImage} from "@angular/common";
import {SignUpIiComponent} from './pages/extranet/sign-up/sign-up-ii/sign-up-ii.component';
import {SignUpIComponent} from './pages/extranet/sign-up/sign-up-i/sign-up-i.component';
import {SignUpIiiComponent} from './pages/extranet/sign-up/sign-up-iii/sign-up-iii.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UnderscoreToSpacePipe} from "./shared/pipes/underscore-to-space.pipe";
import {environment} from "../environments/environment";
import {BASE_PATH} from "../../sdk/figoseal-api-sdk";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatStepperModule} from "@angular/material/stepper";
import {MatFormFieldModule} from "@angular/material/form-field";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {ResetPasswordComponent} from './pages/extranet/reset-password/reset-password.component';
import {BsModalService} from "ngx-bootstrap/modal";
import {AdminModule} from "./pages/intranet/admin/admin.module";
import {LayoutComponent} from "./pages/intranet/admin/layout/layout.component";
import {AuthInterceptor} from "./interceptor/auth.interceptor";
import {BusinessModule} from "./pages/intranet/business/business.module";
import { EmailConfirmationComponent } from './pages/extranet/email-confirmation/email-confirmation.component';
import { ConfirmComponent } from './pages/extranet/confirm/confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ForbiddenComponent,
    SignUpComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignUpIiComponent,
    SignUpIComponent,
    SignUpIiiComponent,
    ResetPasswordComponent,
    LayoutComponent,
    EmailConfirmationComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AdminModule,
    BusinessModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    MatSlideToggleModule,
    MatStepperModule,
    MatFormFieldModule
  ],
  providers: [
    UnderscoreToSpacePipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: BASE_PATH,
      useValue: environment.siteUrl
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    },
    BsModalService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
