/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InviteBusinessUserDTO } from './invite-business-user-dto';


export interface InviteUsersDTO { 
    businessId?: string;
    users?: Array<InviteBusinessUserDTO> | null;
}

