/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FilterVM { 
    /**
     * Any of the Property(ies) of the model will searched using the Query if provided
     */
    query?: string | null;
    /**
     * Current Page to return    Default to 1
     */
    page?: number;
    /**
     * Data to return per page    Default to 100
     */
    pageLimit?: number;
}

