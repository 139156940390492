import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService, ConfirmEmailDTO, LoginDTO} from "../../../../../sdk/figoseal-api-sdk";
import {XAlertMessage} from "../../../shared/components/alert-message/alert-message.component";
import {HelperService} from "../../../services/helper.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../../shared/utils/utils";
import {ConfirmService} from "../../../services/confirm.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  xAlertMessage: XAlertMessage = {
    message: undefined,
    messageType: 'info'
  };
  action: any;

  form: FormGroup;
  passwordFieldType: string = 'password';
  isAuthenticating: boolean;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private helperService: HelperService,
    private confirmService: ConfirmService,
    private router: Router,
  ) {
    this.action = confirmService.action;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [this.action ? this.confirmService.email : '', [Validators.required, Validators.pattern(Utils.EMAIL_REGEX)]],
      password: ['', [Validators.required]]
    });
  }

  async doLogin(): Promise<void> {

    this.isAuthenticating = true;

    const loginDto: LoginDTO = {
      email: this.form.get('email').value.toString().trim(),
      password: this.form.get('password').value
    }

    this.accountService.accountLoginPost({loginDTO: loginDto}).subscribe({
      next: (v) => {
        localStorage.setItem('token', v.data.token);
        const userAccess = this.helperService.determineUserType(v.data.roles);
        console.log(v, userAccess)
        //TODO: Update After login router
        this.router.navigate([this.helperService.determineUserType(v.data.roles)]);
        this.isAuthenticating = false;
      },
      error: (error: unknown) => {
        this.isAuthenticating = false;
        this.showMessage(this.helperService.getError(error), 'danger');
      }
    });
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  showMessage(error: string, type: 'info' | 'primary' | 'success' | 'danger' | 'warning'): void {
    this.xAlertMessage.messageType = type;
    this.xAlertMessage.message = error;
    setTimeout(() => {
      this.xAlertMessage.message = undefined;
    }, 4000);
  }

}
