import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Storage} from "../constants/constant";


@Injectable({
    providedIn: 'root'
})
export class PageManager {
    private _storeName = '_session-store';

    private store: any;

    private _subs: Subscription[] = [];

    constructor(public router: Router) {}


    getStore(storageType: Storage): unknown {
        const sessionStore = this.isLocal(storageType)
            ? localStorage.getItem(this._storeName)
            : sessionStorage.getItem(this._storeName);
        return sessionStore ? JSON.parse(sessionStore) : {};
    }

    public storeData(
        scope: string,
        key: string,
        data: any,
        storageType: Storage = Storage.LOCAL
    ): boolean {
        this.store = this.getStore(storageType);
        if (!this.store[scope]) {
            this.store[scope] = {};
        }
        this.store[scope][key] = JSON.stringify(data);

        this.persist(storageType);

        return this.store[scope];
    }

    public getData(
        scope: string,
        key: string,
        storageType: Storage = Storage.LOCAL
    ): unknown | boolean {

        this.store = this.getStore(storageType);
        if (!this.store[scope] || !this.store[scope][key]) {
            return false;
        }
        return JSON.parse(this.store[scope][key]);
    }

    public removeData(
        scope: string,
        key: string,
        storageType: Storage = Storage.LOCAL
    ): boolean {

        this.store = this.getStore(storageType);

        if (!this.store[scope] || !this.store[scope][key]) {
            return false;
        }
        delete this.store[scope][key];

        this.persist(storageType);

        return true;
    }

    public clearAllData(): void {
        localStorage.clear();
        sessionStorage.clear();
    }

     isLocal(type: Storage): boolean {
        return type === Storage.LOCAL;
    }

    private persist(storageType: Storage): void {
        if (this.isLocal(storageType)) {
            localStorage.setItem(this._storeName, JSON.stringify(this.store));
        } else {
            sessionStorage.setItem(this._storeName, JSON.stringify(this.store));
        }
    }
}
