// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.user-card {
  padding: 7px;
  border-radius: 9px;
  border: 1px solid #ccc5c5;
  background-color: #faf7f7;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: rgba(215, 220, 215, 0.68);
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/intranet/admin/management/edit-permission/edit-permission.component.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C","sourcesContent":["\n.user-card {\n  padding: 7px;\n  border-radius: 9px;\n  border: 1px solid #ccc5c5;\n  background-color: #faf7f7;\n}\n\n.avatar {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  background-color: rgba(215, 220, 215, 0.68);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
