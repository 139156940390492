/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BusinessType } from './business-type';


export interface CompanyVM { 
    id?: string;
    dateCreated?: string;
    createdBy?: string | null;
    dateModified?: string | null;
    modifiedBy?: string | null;
    deleted?: boolean;
    dateDeleted?: string | null;
    deletedBy?: string | null;
    name?: string | null;
    address?: string | null;
    state?: string | null;
    country?: string | null;
    isActive?: boolean;
    dateRegistered?: string;
    businessType?: BusinessType;
    rcNumber?: string | null;
    status?: string | null;
    email?: string | null;
    avatarUrl?: string | null;
    kycLevel?: number;
}

