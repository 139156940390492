import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeOnly'
})
export class TimeOnlyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    const m = moment(value);
    return m.isValid() ? m.format('HH:mm') : null; //04:30
  }
}
