import {BehaviorSubject} from "rxjs";
import {CompanyVM} from "../../../sdk/figoseal-api-sdk";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class BusinessInfo {

  private _business: BehaviorSubject<CompanyVM> = new BehaviorSubject<CompanyVM>(null);


  get business(): BehaviorSubject<CompanyVM> {
    return this._business;
  }

  set business(business: CompanyVM) {
    this._business.next(business);
  }
}
