/**
 * Figoseal API
 * Product Verification
 *
 * The version of the OpenAPI document: v1
 * Contact: info@figoseal.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BusinessType } from './business-type';
import { Document } from './document';


export interface Company { 
    id?: string;
    dateCreated?: string;
    createdBy?: string | null;
    dateModified?: string | null;
    modifiedBy?: string | null;
    deleted?: boolean;
    dateDeleted?: string | null;
    deletedBy?: string | null;
    name: string;
    userId?: string;
    address: string;
    state: string;
    country: string;
    logoId?: string | null;
    isActive?: boolean;
    dateRegistered: string;
    businessType?: BusinessType;
    rcNumber?: string | null;
    status?: string | null;
    email: string;
    contactPhone?: string | null;
    countryOfIncorporation?: string | null;
    dateOfIncorporation?: string | null;
    contactName?: string | null;
    category?: string | null;
    cacUrl?: string | null;
    avatarUrl?: string | null;
    kycLevel?: number;
    documents?: Array<Document> | null;
}

