import {Component, OnInit} from '@angular/core';
import {
  BusinessService,
  CompanyUserDTO,
  CompanyVM,
  FilterVM,
} from "../../../../../../sdk/figoseal-api-sdk";
import {BusinessInfo} from "../../../../services/business";
import {HelperService} from "../../../../services/helper.service";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {

  business: CompanyVM;
  loading = false;

  members: Array<CompanyUserDTO>;
  membersCount: number;

  query = new FormControl('');

  filter: {params: FilterVM, pageCount: number};

  constructor(
    private businessInfo: BusinessInfo,
    private businessService: BusinessService,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.businessInfo.business.subscribe(value => {
      this.business = value;
      this.getBusinessUsers(value?.id);
      this.getMembersCount(value?.id)
    })
  }

  getMembersCount(businessId: string) {
    this.businessService.businessUsersCountGet({businessId})
      .subscribe({
        next: value => {
          this.membersCount = value.data.totalUsers;
        },
        error: err => {
          this.loading = false;
          console.log(this.helperService.getError(err))
        }
      })
  }

  filterUsers() {
    const query = this.query.value;
    console.log(query)
    this.getBusinessUsers(this.business.id, query)
  }

  getBusinessUsers(businessId: string, query?: string, page?: number, pageLimit = 20)  {
    this.businessService.businessUsersGet({businessId, query, page, pageLimit})
      .subscribe({
        next: result => {
          console.log(result);
          this.members = result.data;
          this.filter = {params: result.filter, pageCount: result.pagination.currentPageData};
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          console.log(this.helperService.getError(err))
        }
      })
  }
}
